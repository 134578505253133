<template>
  <div>
    <GmapMap
      :center="{lat: -16.713831805912417, lng: -49.250537109374996}"
      :zoom="12"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false
      }"
      ref="mapRef"
      map-type-id="terrain"
      style="width: auto; height: 86vh; margin: 0 padding: 0; position: relative"
    >
      <template v-slot:visible>
        <v-row
          class="maps-toolbar"
        >
          <v-col cols="12" sm="12" md="2" class="btn-controller-wrap py-0">
            <v-btn
              @click="reload()"
              class="me-2 mb-1"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>

            <v-btn
              @click="toggleMarkers()"
              class="me-2 mb-1"
            >
              <v-icon>{{labelBtnToggleMarkers}}</v-icon>
            </v-btn>

            <v-btn
              @click="filtersVisible = !filtersVisible"
              class="me-2 mb-1"
            >
              <v-icon>{{labelBtnToggleFilters}}</v-icon>
            </v-btn>
          </v-col>

          <template v-if="filtersVisible">

              <v-col cols="7" sm="9" md="1" class="py-0">
                <v-text-field
                  type="text"
                  class="pa-0"
                  background-color="#f5f5f5"
                  v-model="codRevenda"
                  :error-messages="revendaErrors"
                  label="Cod. Revenda"
                  v-on:keyup.enter="filterReseller()"
                ></v-text-field>
              </v-col>

              <v-col cols="5" sm="2" md="1" class="py-0">
                <v-btn @click="filterReseller()" class="btn-filtrar">
                  Filtrar revenda
                </v-btn>
              </v-col>

              <v-col cols="12" sm="6" md="3" class="py-0">
                <vc-date-picker 
                  :max-date='new Date()'
                  v-model="date" 
                  mode="dateTime" 
                  is-range 
                  is24hr 
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      class="pa-0"
                      background-color="#F5F5F5"
                      :value="inputValue.start ? `${inputValue.start} - ${inputValue.end}` : ''"
                      v-on="inputEvents.start"
                      append-icon="mdi-calendar"
                    />
                  </template>
                </vc-date-picker>
              </v-col>

              <v-col cols="7" sm="3" md="1" class="py-0">
                <v-text-field
                  type="text"
                  class="pa-0"
                  background-color="#f5f5f5"
                  v-model="codPromotor"
                  :error-messages="promotorErrors"
                  label="Cod. Promotor"
                  v-on:keyup.enter="filterPromoter()"
                ></v-text-field>
              </v-col>

              <v-col cols="5" sm="2" md="1" class="py-0">
                <v-btn class="btn-filtrar" @click="filterPromoter()">
                  Filtrar promotor
                </v-btn>
              </v-col>

          </template>
        </v-row>

        <v-container
          fluid class="supervisors-toolbar"
          v-if="isAdmin"
        >
          <v-btn
            @click="supervisorsVisible = !supervisorsVisible"
            class="mb-1"
          ><v-icon>{{labelBtnToggleSupervisors}}</v-icon></v-btn>

          <v-btn-toggle
            v-model="supervisorsParentPath"
            class="supervisors-btn-group"
            mandatory
            multiple
            max="3"
            v-if="supervisorsVisible"
          >
            <v-btn
              v-for="supervisor in supervisors" :key="supervisor.text"
              :value="supervisor.value.parentPath"
              rounded
              small
              class="supervisors-btn"
            >
              {{supervisor.text}}
            </v-btn>
          </v-btn-toggle>
        </v-container>
      </template>
    </GmapMap>
  </div>
</template>

<script
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyD1PUja1fvW0QilSCknKKV8QjLdH_GzsgQ&callback=initMap&v=weekly"
></script>
<style>
.map-label {
  color: white;
  background-color: white;
  white-space: nowrap;
}
</style>
<script>
import { gmapApi } from 'vue2-google-maps'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import moment from 'moment'
import { size } from 'lodash'
import variables from '@/assets/styles/helpers/_variables.scss'

const namespaceStore = "geolocations"

export default {
  name: 'Geolocations',
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('roles', ['permiteAcao']),

    google: gmapApi,
    
    variables: () => variables,

    labelBtnToggleMarkers () {
      if (this.markersVisible) return 'mdi-home-off'
      return 'mdi-home'
    },

    labelBtnToggleFilters () {
      if (this.filtersVisible) return 'mdi-filter-off'
      return 'mdi-filter'
    },

    labelBtnToggleSupervisors () {
      if (this.supervisorsVisible) return 'mdi-briefcase-off'
      return 'mdi-briefcase'
    },
  },
  data: () => ({
    resellers: [],
    promoters: [],
    polyLines: [],
    lineMarkers: [],
    supervisors: [],
    map: null,
    markersVisible: true,
    filtersVisible: true,
    supervisorsVisible: true,
    codRevenda: '',
    revendaErrors: [],
    codPromotor: '',
    promotorErrors: [],
    date: {
      start: new Date().setHours(6,0,0,0),
      end: new Date(),
    },
    timer: '',
    supervisorsParentPath: [],
    isAdmin: false,
  }),
  watch: {
    'supervisorsParentPath' (value) {
      this.getResellers(value)
    },
  },
  mounted() {
    this.$nextTick(()=>{
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.map = map
        map.panTo({lat: -16.713831805912417, lng: -49.250537109374996})
      })
    });

    if (this.permiteAcao({path: '/gestao/geolocalizacao/listar-supervisores'}, '')) {
      this.getSupervisor()
        .then(items => {
          this.supervisors = items
          this.isAdmin = size(items) > 0
        })
        .catch(() => this.isAdmin = false)
        .finally(() => this.init())
    } else {
      this.isAdmin = false
      this.init()
    }
  },
  
  methods: {
    ...mapActions(namespaceStore, ['getGeolocations', 'salvarPosicaoRevenda','getRastroPromotores','getPromotoresLastPosition', 'getSupervisor']),

    formatPhone (phone) {
      if (size(phone) === 13) return `(${phone.slice(2, 4)}) ${phone.slice(4, 9)}-${phone.slice(9, 13)}`
      return phone
    },
    addLineMarkers(start,position,map,cod) {
      window.vue = this

      let marker = new google.maps.Marker({
        position: {lat:position.latitude,lng:position.longitude},
        label: {color: '#203334', fontWeight: 'bold', fontSize: '16px',text:`${start ? 'Começo' : 'Fim'}`,className: "map-label"},
        draggable : false,
        map: map,
      })
      
      const infowindow = new google.maps.InfoWindow({
        content: `
          <div id="content">
            <span><strong>${start ? 'Começo' : 'Fim'}</strong></span><br/>
            <span><strong>Data</strong>: ${position.gps_date}</span><br/>
            <span><strong>Cod</strong>: ${cod}</span><br/>
          </div>
        `
      })

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        })
      })
      
      return marker
    },
    addMarker(entity,entityTypeId,lat,lng,map,draggable=false) {
      window.vue = this
      let marker = new google.maps.Marker({
        position: {lat:lat,lng:lng},
        label: {color: '#203334', fontWeight: 'bold', fontSize: '16px',text:`${entity.cod}`,className: "map-label"},
        id: entity.id,
        icon: {
          labelOrigin: new google.maps.Point(16,40),
          url: entityTypeId == 4 ? 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com/map-icons/icons8-shop-30.png' : 'https://s3-prd-images-pub.s3.us-west-2.amazonaws.com/map-icons/icons8-biker-40.png'
        },
        draggable: draggable,
        map: map,
      });

      const revLinkSave = `<a id="saveGeolocation" onclick="window.vue.markerClick(${entity.id})">Salvar</a>`
      const revLinkDraggable = `<a id="moverRevenda" onclick="window.vue.markerToggleDraggeble(${entity.id})">${draggable ? 'Fixar' : 'Mover'}</a>`
      
      const infowindow = new google.maps.InfoWindow({
        content: entityTypeId === 4 ? `
          <div id="content">
            <span><strong>Cod</strong>: ${entity.cod}</span><br/>
            <span><strong>Nome</strong>: ${entity.json_data.name.slice(0, 15)}</span><br/>
            <span><strong>Telefone</strong>: ${this.formatPhone(entity.json_data.phone)}</span><br/>
            <span><strong>Cod. Promotor</strong>: ${entity.promoter_cod}</span><br/><br/>
            <div class="infoWindowActions">${revLinkDraggable} ${revLinkSave}</div>
          </div>
        `
        :
        `<div id="content">
          <span><strong>Cod</strong>: ${entity.cod}</span><br/>
          <span><strong>Ultima transmissão:</strong>: ${entity.gpsDate}</span>
        </div>`,
      });

      marker.entity = entity
      marker.entityTypeId = entityTypeId

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        });
      });
      return marker
    },
    addLine(lineCod,arrayCoordinates) {
      window.vue = this

      const flightPlanCoordinates = arrayCoordinates.reverse().map(it => {return {lat: it.latitude, lng: it.longitude}})
      const flightPath = new google.maps.Polyline({
        path: flightPlanCoordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons: [{
          icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
          offset: '100%',
          repeat: '150px'
        }]
      });

      flightPath.setMap(this.map);
      this.polyLines.push(flightPath)
    },

    reload () {
      this.markersVisible = true
      if (this.codRevenda.length !== 0) this.filterReseller()
      else if (this.codPromotor.length !== 0) this.filterPromoter()
      else {
        this.init()
        this.date = {
          start: new Date().setHours(6,0,0,0),
          end: new Date,
        }
      }
    },

    init() { 
      this.clearMap()
      this.codRevenda = ''
      this.revendaErrors = []
      this.codPromotor = ''
      this.promotorErrors = []
      
      // BUSCAR RESSELERS
      this.getResellers(this.supervisorsParentPath)

      // BUSCAR PROMOTER LAST POSITIONS
      this.getPromoter()
      this.startAutoUpdate()
    },
    markerClick(markerId) {
      let marker = this.resellers.find(it => it.id == markerId)
      if(marker) {
        this.salvarPosicaoRevenda(marker)
          .then(() => successSnackbar('Posição da revenda salva com sucesso.'))
          .catch(() => errorSnackbar('Erro ao salvar posição, contate o suporte.'))
      }
      return ;
    },
    markerToggleDraggeble(markerId) {
      window.vue = this
      const oldMarker = this.resellers.find(it => it.id === markerId)
      if (oldMarker) {
        oldMarker.setMap(null)
        const newMarker = this.addMarker(oldMarker.entity,oldMarker.entityTypeId,oldMarker.internalPosition.lat(),
          oldMarker.internalPosition.lng(),this.map,!oldMarker.draggable)

        this.resellers.splice(this.resellers.indexOf(oldMarker), 1)
        this.resellers.push(newMarker)
      }
    },
    setResellersMarkers(list = []) {
      list.map(it => {
          if((it.lat != null  && it.lng != null) || (it.lat_cep != null  && it.lng_cep != null)) {
            this.resellers.push(this.addMarker(it,4,Number(it.lat || it.lat_cep),Number(it.lng || it.lng_cep),this.map))
          }
        }
      )
    },
    toggleMarkers() {
      this.markersVisible = !this.markersVisible
      this.resellers.forEach(it => {
        if (this.markersVisible) it.setMap(this.map)
        else it.setMap(null)
      })
    },
    clearResellers() {
      this.resellers.forEach(it => { it.setMap(null) })
      this.resellers = []
    },
    clearPromoters() {
      this.promoters.forEach(it => { it.setMap(null) })
      this.promoters = []
    },
    clearLines() {
      this.polyLines.forEach(it => { it.setMap(null) })
      this.promoters = []

      this.lineMarkers.forEach(it => { it.setMap(null) })
      this.lineMarkers = []
    },
    clearMap() {
      this.cancelAutoUpdate()
      this.clearResellers()
      this.clearPromoters()
      this.clearLines()
    },
    filterReseller() {
      if(this.codRevenda.length === 0) {
        this.revendaErrors.push('Campo necessário.')
        errorSnackbar('Código da Revenda não informado.')
        return
      }
      this.revendaErrors = []
      this.markersVisible = true

      this.getResellers(this.supervisorsParentPath, this.codRevenda, false)
    },
    filterPromoter() {
      if(this.codPromotor.length === 0) {
        this.promotorErrors.push('Campo necessário.')
        errorSnackbar('Código do Promotor não informado.')
        return 
      }
      this.promotorErrors = []
      this.markersVisible = true
      
      if (!this.date.start || !this.date.end) {
        errorSnackbar('Data da rota não informada')
        return
      }
      
      let _addlFilter = {
        'gpsDate_>=': moment(this.date.start).format('YYYY-MM-DD HH:mm'),
        'gpsDate_<=': moment(this.date.end).format('YYYY-MM-DD HH:mm'),
      }

      this.clearMap()

      // PEGANDO OS RESELLERS DO PROMOTER
      this.getResellers(this.supervisorsParentPath, this.codPromotor, true)

      // PEGANDO O RASTRO DO PROMOTER
      this.getRastroPromotores({ cod: this.codPromotor, _addlFilter, })
      .then(result => {
          if(size((result.data.resultMap || [])) > 0) {
            let positions = result.data.resultMap
            this.addLine('Rev1',positions)
            this.lineMarkers.push(this.addLineMarkers(true,positions[0],this.map,this.codPromotor))
            this.lineMarkers.push(this.addLineMarkers(false,positions[positions.length - 1],this.map,this.codPromotor))
          }
          else {
            this.getPromoter(this.codPromotor)
            errorSnackbar('Promotor não tem dados de rota cadastrado.') 
          }
      })
    },
    getResellers (parentPath = [], cod = '', promoterFilter = false) {
      let params = {
        cod: cod,
        isPromoter: promoterFilter
      }
      if (this.isAdmin) params.supervisorsParentPath = parentPath

      this.getGeolocations(params)
      .then(result => {
        this.clearResellers()
        this.setResellersMarkers(result.data.resultMap)
      })
    },
    getPromoter (cod = null) {
      this.getPromotoresLastPosition({ cod })
      .then(result => {
        this.clearPromoters() // LIMPANDO MARKERS de promotores
        result.data.resultMap.map(it => {
          this.promoters.push(this.addMarker(it,3,Number(it.latitude),Number(it.longitude),this.map))
        })
      })
    },
    startAutoUpdate () {
      this.timer = setInterval(this.getPromoter, 60 * 1000)
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  }
  
}
</script>

<style lang="scss" scoped>
.vue-map-container {
  overflow: hidden;
}
.maps-toolbar {
  padding-top: 10px;
  justify-content: center;
}
.btn-controller-wrap {
  display: flex;
  justify-content: center;
}
.search-input {
  border-radius: 4px;
}
.supervisors-toolbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .supervisors-btn-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent !important;;

    .supervisors-btn {
      margin: 2px 0px;
      opacity: 1;
      border-radius: 4px;
    }
  }
}
.btn-filtrar {
  float: right;
}

@media (max-width: 425px) {
  .maps-toolbar {
    padding-top: 13px;
  }
  .btn-controller-wrap {
    justify-content: flex-start;
  }
  .datepicker-inputs {
    display: none;
  }
}
</style>

<style lang="scss">
#content {
  span {
    font-size: 16px;
  
    strong {
      font-weight: 700;
    }
  }
}
.infoWindowActions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    font-weight: 800;

    &:hover {
      text-decoration-line: underline;
    }
  }
}
</style>