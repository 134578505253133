var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"auto","height":"86vh","margin":"0 padding: 0","position":"relative"},attrs:{"center":{lat: -16.713831805912417, lng: -49.250537109374996},"zoom":12,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUI: false
    },"map-type-id":"terrain"},scopedSlots:_vm._u([{key:"visible",fn:function(){return [_c('v-row',{staticClass:"maps-toolbar"},[_c('v-col',{staticClass:"btn-controller-wrap py-0",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"me-2 mb-1",on:{"click":function($event){return _vm.reload()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{staticClass:"me-2 mb-1",on:{"click":function($event){return _vm.toggleMarkers()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.labelBtnToggleMarkers))])],1),_c('v-btn',{staticClass:"me-2 mb-1",on:{"click":function($event){_vm.filtersVisible = !_vm.filtersVisible}}},[_c('v-icon',[_vm._v(_vm._s(_vm.labelBtnToggleFilters))])],1)],1),(_vm.filtersVisible)?[_c('v-col',{staticClass:"py-0",attrs:{"cols":"7","sm":"9","md":"1"}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"type":"text","background-color":"#f5f5f5","error-messages":_vm.revendaErrors,"label":"Cod. Revenda"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterReseller()}},model:{value:(_vm.codRevenda),callback:function ($$v) {_vm.codRevenda=$$v},expression:"codRevenda"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5","sm":"2","md":"1"}},[_c('v-btn',{staticClass:"btn-filtrar",on:{"click":function($event){return _vm.filterReseller()}}},[_vm._v(" Filtrar revenda ")])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('vc-date-picker',{attrs:{"max-date":new Date(),"mode":"dateTime","is-range":"","is24hr":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticClass:"pa-0",attrs:{"background-color":"#F5F5F5","value":inputValue.start ? ((inputValue.start) + " - " + (inputValue.end)) : '',"append-icon":"mdi-calendar"}},inputEvents.start))]}}],null,false,1143482540),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"7","sm":"3","md":"1"}},[_c('v-text-field',{staticClass:"pa-0",attrs:{"type":"text","background-color":"#f5f5f5","error-messages":_vm.promotorErrors,"label":"Cod. Promotor"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterPromoter()}},model:{value:(_vm.codPromotor),callback:function ($$v) {_vm.codPromotor=$$v},expression:"codPromotor"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5","sm":"2","md":"1"}},[_c('v-btn',{staticClass:"btn-filtrar",on:{"click":function($event){return _vm.filterPromoter()}}},[_vm._v(" Filtrar promotor ")])],1)]:_vm._e()],2),(_vm.isAdmin)?_c('v-container',{staticClass:"supervisors-toolbar",attrs:{"fluid":""}},[_c('v-btn',{staticClass:"mb-1",on:{"click":function($event){_vm.supervisorsVisible = !_vm.supervisorsVisible}}},[_c('v-icon',[_vm._v(_vm._s(_vm.labelBtnToggleSupervisors))])],1),(_vm.supervisorsVisible)?_c('v-btn-toggle',{staticClass:"supervisors-btn-group",attrs:{"mandatory":"","multiple":"","max":"3"},model:{value:(_vm.supervisorsParentPath),callback:function ($$v) {_vm.supervisorsParentPath=$$v},expression:"supervisorsParentPath"}},_vm._l((_vm.supervisors),function(supervisor){return _c('v-btn',{key:supervisor.text,staticClass:"supervisors-btn",attrs:{"value":supervisor.value.parentPath,"rounded":"","small":""}},[_vm._v(" "+_vm._s(supervisor.text)+" ")])}),1):_vm._e()],1):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }